<form id="enterprise-trial-form" class="form" [formGroup]="trialForm">
  <div class="form--field -wide-label -required">
    <label class="form--label" for="trial-company-name">{{ text.label_company }}</label>
    <div class="form--field-container">
      <div class="form--text-field-container">
        <input type="text"
               id="trial-company-name"
               class="form--text-field"
               formControlName="company">
      </div>
    </div>
  </div>
  <div class="form--field -wide-label -required">
    <label class="form--label" for="trial-first-name">{{ text.label_first_name }}</label>
    <div class="form--field-container">
      <div class="form--text-field-container">
        <input type="text"
               id="trial-first-name"
               class="form--text-field"
               formControlName="first_name">
      </div>
    </div>
  </div>
  <div class="form--field -wide-label -required">
    <label class="form--label" for="trial-last-name">{{ text.label_last_name }}</label>
    <div class="form--field-container">
      <div class="form--text-field-container">
        <input type="text"
               id="trial-last-name"
               class="form--text-field"
               formControlName="last_name">
      </div>
    </div>
  </div>
  <div class="form--field -wide-label -required">
    <label class="form--label" for="trial-email">{{ text.label_email }}</label>
    <div class="form--field-container">
      <div class="form--text-field-container"
           [ngClass]="{ '-required-highlighting' : eeTrialService.emailError }">
        <input type="email"
               class="form--text-field"
               id="trial-email"
               formControlName="email" (blur)="checkMailField()">
      </div>
    </div>
    <div *ngIf="eeTrialService.emailError" class="form--field-instructions">{{ eeTrialService.errorMsg }}</div>
  </div>
  <div class="form--field -wide-label -required">
    <label class="form--label" for="trial-domain-name">{{ text.label_domain }}</label>
    <div class="form--field-container">
      <div class="form--text-field-container"
           [ngClass]="{ '-required-highlighting' : eeTrialService.domainTaken }">
        <input type="text"
               id="trial-domain-name"
               class="form--text-field"
               formControlName="domain">
      </div>
    </div>
    <div *ngIf="eeTrialService.domainTaken" class="form--field-instructions">{{ eeTrialService.errorMsg }}</div>
  </div>
  <div class="form--field -required">
    <div class="form--field-container">
      <label class="form--label-with-check-box -no-ellipsis" for="trial-general-consent">
        <div class="form--check-box-container">
          <input type="checkbox"
                 id="trial-general-consent"
                 class="form--check-box"
                 formControlName="general_consent"
                 required>
        </div>
        <span [innerHTML]="text.general_consent"></span>
      </label>
    </div>
  </div>
  <div class="form--field">
    <div class="form--field-container">
      <label class="form--label-with-check-box -no-ellipsis" for="trial-newsletter-consent">
        <div class="form--check-box-container">
          <input type="checkbox"
                 id="trial-newsletter-consent"
                 class="form--check-box"
                 formControlName="newsletter_consent">
        </div>
        <span [innerHtml]="text.receive_newsletter"></span>
      </label>
    </div>
  </div>
</form>