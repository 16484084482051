<enterprise-active-trial></enterprise-active-trial>

<p>{{ text.confirmation_info }}</p>
<p>
  <span>{{ text.status_label }} </span>
  <span *ngIf="!eeTrialService.confirmed; else confirmedStatus" class="status--waiting">
    {{ text.status_waiting }}

    <a id="resend-link" (click)="resendMail()">{{ text.resend }}</a>
    <p *ngIf="eeTrialService.cancelled">{{ text.session_timeout }}</p>
  </span>

  <ng-template #confirmedStatus>
    <span class="status--confirmed icon-yes"> {{ text.status_confirmed }}</span>
  </ng-template>
</p>
