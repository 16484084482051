<div class="top-menu-search">
    <a *ngIf="expanded"
       (click)="toggleMobileSearch()"
       class="top-menu-search--back-button"
       href="#"
       title="{{text.close_search}}">
      <i class="icon-arrow-left1" aria-hidden="true"></i>
    </a>
    <ng-select #select
              [items]="requests.output$ | async"
              name="global-search-input"
              id="global-search-input"
              placeholder="{{text.search_dots}}"
              accesskey="4"
              [ngClass]="{'top-menu-search--input': true, '-markable': markable }"
              [(ngModel)]="selectedItem"
              [class.-expanded]="expanded"
              [openOnEnter]="false"
              [searchFn]="customSearchFn"
              [typeahead]="requests.input$"
              [loading]="requests.loading$ | async"
              (focus)="onFocus()"
              (blur)="onFocusOut()"
              (search)="search($event)"
              (open)="openCloseMenu(currentValue)"
              (close)="select.searchTerm = currentValue"
              (change)="followSelectedItem()"
              (keydown.enter)="onEnterBeforeResultsLoaded()"
              (keydown.escape)="blur()"
              (clear)="clearSearch()">
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div *ngIf="!item.id; else workPackageItemTemplate">
            <div tabindex="-1" class="global-search--option"(click)="followItem(item)">
              <div class="global-search--option-wrapper">
                <span class="global-search--search-term"> {{currentValue}} </span>
                <span class="global-search--project-scope" title="{{item.projectScope}}"> {{item.text}} ↵ </span>
              </div>
            </div>
          </div>
          <ng-template #workPackageItemTemplate>
            <div tabindex="-1" class="global-search--option" (click)="redirectToWp(item.id, $event)">
              <a class="global-search--option-wrapper"
                 [href]="wpPath(item.id)">
                <span class="global-search--wp-id"
                      title="{{item.status}}"
                      [ngOptionHighlight]="currentValue"
                      [ngClass]="statusHighlighting(item.statusId)"> #{{item.id}} </span>
                <span class="global-search--wp-subject" [ngOptionHighlight]="currentValue"> {{item.subject}} </span>
              </a>
            </div>
        </ng-template>
      </ng-template>
    </ng-select>
    <a #btn
       id="top-menu-search-button"
       class="top-menu-search--button search-form-normal"
       title="{{text.search}}"
       tabindex="0"
       role="button"
       [class.-input-focused]="expanded"
       (accessibleClick)="handleClick($event)">
       <op-icon icon-classes="icon5 icon-search ellipsis"></op-icon>
    </a>
</div>
