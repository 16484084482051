<div class="work-packages-partitioned-query-space--container"
      [ngClass]="currentPartition">
  <div class="toolbar-container -editable">
    <div class="toolbar">
      <editable-toolbar-title [title]="selectedTitle"
                              [inFlight]="querySaving"
                              [showSaveCondition]="hasQueryProps"
                              (onSave)="saveQueryFromTitle($event)"
                              (onEmptySubmit)="updateQueryName('')"
                              [editable]="titleEditingEnabled">
      </editable-toolbar-title>

      <ul class="toolbar-items hide-when-print"
          *ngIf="tableInformationLoaded">

        <ng-container *ngFor="let definition of toolbarButtonComponents">
          <li class="toolbar-item" *ngIf="!definition.show || definition.show()" [ngClass]="definition.containerClasses">
            <ndc-dynamic [ndcDynamicComponent]="definition.component"
                         [ndcDynamicInputs]="definition.inputs"
                         [ndcDynamicInjector]="injector"
                         [ndcDynamicOutputs]="definition.outputs">
            </ndc-dynamic>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <filter-container></filter-container>

  <div class="work-packages-partitioned-page--content-container">
    <!-- Left content view -->
    <div class="work-packages-partitioned-page--content-left loading-indicator--location"
         data-indicator-name="table"
         ui-view="content-left">
    </div>

    <!-- Right content view -->
    <div class="work-packages-partitioned-page--content-right"
         ui-view="content-right"></div>
  </div>
</div>
